import React, {useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import Modal from "react-modal";
import {useTranslation} from "react-i18next";
import {isMobile} from "react-device-detect";
const CompanyDNA = () => {
  const { t } = useTranslation();

  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);
  const [openModal5, setOpenModal5] = useState(false);


  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '40px',
      width: '580px',
      transform: 'translate(-50%, -50%)'
    },
  };
  if(isMobile){
    modalStyles.content.width= '100%'
  }

  Modal.setAppElement('#root');



  return (
    <div>
      <section className="intro7_Dna_Section">
        <div className="container">
          <h2>셀러허브 DNA</h2>
          <div className="info7_Container">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                "@0.749": {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                "@0.75": {
                  slidesPerView: 5,
                  spaceBetween: 0,
                },
                "@1.00": {
                  slidesPerView: 5,
                  spaceBetween: 16,
                }
              }}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="ShDNA large">
                  <div className="item" onClick={()=> setOpenModal1(true)}>
                    <img src="/assets/images/face@2x.png" alt="face" />
                    <h1 dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal1.title')}`}}/>
                    <button className="view">{t('mainPage.whySellerhub.detailView')}</button>
                  </div>

                  <Modal
                  isOpen={openModal1}
                  onRequestClose={()=> setOpenModal1(false)}
                  style={modalStyles}
                  contentLabel="Modal"
                >
                  <div className="modalContentsDna">
                    <button className="layerClose" onClick={()=> setOpenModal1(false)}><img src="./assets/images/close@2x.png" alt="Close" /></button>
                    <p className="icon"><img src="/assets/images/face@2x.png" alt="" /></p>
                    <h3 dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal1.title')}`}}/>

                    <div className="detail">
                      <div className="detailContents">
                        <div className="desc" dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal1.desc')}`}}></div>
                      </div>
                    </div>
                  </div>
                </Modal>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="ShDNA large">
                  <div className="item" onClick={()=> setOpenModal2(true)}>
                    <img src="/assets/images/attack@2x.png" alt="Office building" />
                    <h1 dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal2.title')}`}}></h1>
                    <button className="view">{t('mainPage.whySellerhub.detailView')}</button>
                  </div>

                  <Modal
                    isOpen={openModal2}
                    onRequestClose={()=> setOpenModal2(false)}
                    style={modalStyles}
                    contentLabel="Modal"
                  >
                    <div className="modalContentsDna">
                      <button className="layerClose" onClick={()=> setOpenModal2(false)}><img src="./assets/images/close@2x.png" alt="Close" /></button>
                      <p className="icon"><img src="/assets/images/attack@2x.png" alt="" /></p>
                      <h3 dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal2.title')}`}}></h3>

                      <div className="detail">
                        <div className="detailContents">
                          <div className="desc" dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal2.desc')}`}}></div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="ShDNA small">
                  <div className="item" onClick={()=> setOpenModal3(true)}>
                    <img src="/assets/images/magic@2x.png" alt="OK hand" />
                    <h1 dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal3.title')}`}}/>
                    <button className="view">{t('mainPage.whySellerhub.detailView')}</button>
                  </div>

                  <Modal
                    isOpen={openModal3}
                    onRequestClose={()=> setOpenModal3(false)}
                    style={modalStyles}
                    contentLabel="Modal"
                  >
                    <div className="modalContentsDna">
                      <button className="layerClose" onClick={()=> setOpenModal3(false)}><img src="./assets/images/close@2x.png" alt="Close" /></button>
                      <p className="icon"><img src="/assets/images/magic@2x.png" alt="" /></p>
                      <h3 dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal3.title')}`}}></h3>

                      <div className="detail">
                        <div className="detailContents">
                          <div className="desc" dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal3.desc')}`}}></div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="ShDNA small">
                  <div className="item" onClick={()=> setOpenModal4(true)}>
                    <img src="/assets/images/rainbow@2x.png" alt="OK hand" />
                    <h1 dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal4.title')}`}}></h1>
                    <button className="view">{t('mainPage.whySellerhub.detailView')}</button>
                  </div>

                  <Modal
                    isOpen={openModal4}
                    onRequestClose={()=> setOpenModal4(false)}
                    style={modalStyles}
                    contentLabel="Modal"
                  >
                    <div className="modalContentsDna">
                      <button className="layerClose" onClick={()=> setOpenModal4(false)}><img src="./assets/images/close@2x.png" alt="Close" /></button>
                      <p className="icon"><img src="/assets/images/rainbow@2x.png" alt="" /></p>
                      <h3 dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal4.title')}`}}></h3>

                      <div className="detail">
                        <div className="detailContents">
                          <div className="desc" dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal4.desc')}`}}></div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="ShDNA small">
                  <div className="item" onClick={()=> setOpenModal5(true)}>
                    <img src="/assets/images/genie@2x.png" alt="OK hand" />
                    <h1 dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal5.title')}`}}></h1>
                    <button className="view">{t('mainPage.whySellerhub.detailView')}</button>
                  </div>

                  <Modal
                    isOpen={openModal5}
                    onRequestClose={()=> setOpenModal5(false)}
                    style={modalStyles}
                    contentLabel="Modal"
                  >
                    <div className="modalContentsDna">
                      <button className="layerClose" onClick={()=> setOpenModal5(false)}><img src="./assets/images/close@2x.png" alt="Close" /></button>
                      <p className="icon"><img src="/assets/images/genie@2x.png" alt="" /></p>
                      <h3 dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal5.title')}`}}></h3>

                      <div className="detail">
                        <div className="detailContents">
                          <div className="desc" dangerouslySetInnerHTML={{__html:`${t('CompanyIntro.modal5.desc')}`}}></div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </SwiperSlide>
            </Swiper>

          </div>
        </div>

      </section>
    </div>
  );
};

export default CompanyDNA;
